import { useCallback, useEffect, useRef, useState } from "react";
import { LinkNotification } from "../types/linkSettings";
import LinkSettingsAPI from "../services/linkSettingsAPI";
import ConfirmationModal from "../common/ConfirmationModal";

const Notifications = () => {
    const [notifications, setNotifications] = useState<LinkNotification[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const savedTimeout = useRef<NodeJS.Timeout | null>(null);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [indexToRemove, setIndexToRemove] = useState<number | null>(null);
    const [ , setScoringType] = useState('Standard');
    const [ , setSourceName] = useState('');

    const [sendToAssessmentTaker, setSendToAssessmentTaker] = useState({
        enabled: true,
        notificationType: 'default',
    });

    const fetchLinkSettings = useCallback(async () => {
        try {
            const linkSettings = await LinkSettingsAPI.getDefaultLinkSettings();
            setNotifications(linkSettings.linkInfo.settings.notifications);
            setScoringType(linkSettings.linkInfo.scoringType || 'Standard');
            setSourceName(linkSettings.linkInfo.source || '');
            setSendToAssessmentTaker(linkSettings.linkInfo.sendToAssessmentTaker || {
                enabled: true,
                notificationType: 'default',
            });
        } catch (error) {
            console.error('Failed to fetch link settings:', error);
        }
    }, []);

    useEffect(() => {
        fetchLinkSettings();
    }, [fetchLinkSettings]);

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await LinkSettingsAPI.updateEntityDefualtLinkSettings( {
                notifications,
                sendToAssessmentTaker,
            });
            setHasChanges(false);
            setIsSaved(true);

            if (savedTimeout.current) {
                clearTimeout(savedTimeout.current);
            }
            savedTimeout.current = setTimeout(() => {
                setIsSaved(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to save link settings:', error);
        }
    };

    const handleEmailChange = (index: number, email: string) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications[index].recipientEmail = email;
            return updatedNotifications;
        });
    };

    const handleChange = () => {
        setHasChanges(true);
        setIsSaved(false);
    };

    const handleNotificationTypeChange = (index: number, notificationType: string) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications[index].notificationType = notificationType;
            return updatedNotifications;
        });
    };

    const removeRecipient = (index: number) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications.splice(index, 1);
            return updatedNotifications;
        });
    };

    const addRecipient = () => {
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            { recipientEmail: '', notificationType: '' },
        ]);
    };

    return (<div>
        <h2>Default Link Notifications</h2>
        <h3 className="heading-top-margin">Notifications</h3>
        <form onSubmit={handleSave}>
            {notifications.map((notification, index) => (
                <div key={index} className="notification-row">
                    <input
                        type="email"
                        value={notification.recipientEmail}
                        onChange={(e) => {
                            handleEmailChange(index, e.target.value);
                            handleChange();
                        }}
                        placeholder="Recipient Email"
                        required
                        className="notification-input"
                    />
                    <select
                        value={notification.notificationType}
                        onChange={(e) => {
                            handleNotificationTypeChange(index, e.target.value);
                            handleChange();
                        }}
                        className="notification-select"
                    >
                        <option value="">Notification Type</option>
                        <option value="default">Completion Notification</option>
                        <option value="fullReport">Full Report</option>
                        <option value="chartOnly">Chart Only</option>
                        {/* Add more notification type options as needed */}
                    </select>
                    {notifications.length > 1 && (
                        <button
                            type="button"
                            className="button button-danger"
                            onClick={() => {
                                setIndexToRemove(index)
                                setIsConfirmationModalOpen(true);
                            }}
                        >
                            Remove
                        </button>
                    )}
                </div>
            ))}
            <div className="add-recipient-row">
                <button
                    type="button"
                    className="button button-primary"
                    onClick={() => {
                        addRecipient();
                        handleChange();
                    }}
                >
                    Add Recipient
                </button>
            </div>
            <div className="assessment-taker-notification heading-top-margin">
                <h3>Assessment Taker Notification</h3>
                <div className="notification-row">
                    <label className="notification-input">
                        <input
                            type="checkbox"
                            checked={sendToAssessmentTaker.enabled}
                            onChange={(e) => {
                                setSendToAssessmentTaker((prev) => ({
                                    ...prev,
                                    enabled: e.target.checked,
                                }));
                                handleChange();
                            }}
                        />
                        Send notification to assessment taker
                    </label>
                    {sendToAssessmentTaker.enabled && (
                        <select
                            value={sendToAssessmentTaker.notificationType}
                            onChange={(e) => {
                                setSendToAssessmentTaker((prev) => ({
                                    ...prev,
                                    notificationType: e.target.value,
                                }));
                                handleChange();
                            }}
                            className="notification-select"
                        >
                            <option value="default">Completion Notification</option>
                            <option value="fullReport">Full Report</option>
                            <option value="chartOnly">Chart Only</option>
                        </select>
                    )}
                </div>
            </div>
            <div className="notification-actions">
                <button
                    type="button"
                    className="button button-cancel"
                    onClick={() => {
                        // Reset the link settings to the initial state
                        fetchLinkSettings();
                        setHasChanges(false);
                        setIsSaved(false);
                    }}
                    disabled={!hasChanges}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className={`button ${hasChanges ? 'button-save' : 'button-disabled'}`}
                    disabled={!hasChanges}
                >
                    {isSaved ? 'Saved' : hasChanges ? 'Save Changes' : 'No Changes'}
                </button>
            </div>
        </form>
        <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            confirm={() => {
                setIsConfirmationModalOpen(false);
                if (indexToRemove) {
                    removeRecipient(indexToRemove);
                }
                handleChange();
            }}
            question={"Did you really want to remove it?"}
        />
    </div>)

}

export default Notifications;